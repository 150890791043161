import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Form,
  FormGroup,
  Input,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
  Button,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useToasts } from "react-toast-notifications";
import { postMethod, getUserId } from "../../services/httpServices";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import moment from "moment";
import ReactPaginate from "react-paginate";
import NoRecordFound from "components/NoRecordFound/NoRecordFound";
import classnames from "classnames";

const STATUS = [
  { key: "", value: "Select Loan ID", className: "bg-yellow" },
  { key: "00000036315", value: "00000036315", className: "bg-yellow" },
  { key: "00000010033", value: "00000010033", className: "bg-yellow" },
];
const navItemCls = {
  minWidth: "120px",
  textAlign: "center",
  marginTop: "4px",
  cursor: "pointer",
};

const LoanRepaymentSchedule = () => {
  const [filteredList, setFilteredList] = React.useState([]);
  const [transactionsList, setTransactionsList] = React.useState([]);
  const [currentPage1, setCurrentPage1] = React.useState(0);
  const [currentPage2, setCurrentPage2] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [statusFilter, setStatusFilter] = React.useState(STATUS[0].key);
  const [loanAccount, setLoanAccount] = React.useState(null);
  const [loanProduct, setLoanProduct] = React.useState(null);
  const [activeTab, setActiveTab] = React.useState("1");
  const [loans, setLoans] = useState([]);

  const { addToast } = useToasts();

  // React.useEffect(() => {
  //   if (statusFilter) {
  //     fetchTrans();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [statusFilter]);

  useEffect(() => {
    fetchLoans();
  }, []);

  const fetchLoans = async () => {
    let errorMsg = "";

    setLoading(true);

    let url = "/api/Subscriber/LoanAccounts/GetByUserId/" + getUserId();
    postMethod(url, {}, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            // setLoanAccount(res.data.result.account?.loanAccountDetails);
            // setLoanProduct(res.data.result.account?.loanProduct);
            if (res.data.result.length === 1) {
              setStatusFilter(res.data.result?.[0]?.loanAccountId);
            }
            setLoans(
              res.data.result?.map((item) => ({
                key: item.loanAccountId,
                value: item.loanAccountId,
              })) || []
            );
            // setTransactionsList(res.data.result.account?.transactions);
          } else if (res.data.responseCode === "400") {
            setLoans([]);
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            setLoans([]);
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          setLoans([]);
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Internal Server Error");
      });
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const changeStatus = (val) => {
    var value = val;
    setStatusFilter(value);
  };

  const fetchTrans = async () => {
    let errorMsg = "";
    console.log({ statusFilter });
    if (!statusFilter) {
      return;
    }
    setLoading(true);
    let data = {
      id: statusFilter,
    };

    let url = "/api/Transactions/GetLoanRepaymentSchedule";

    postMethod(url, data, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            setLoanAccount(res.data.result.account?.loanAccountDetails);
            setLoanProduct(res.data.result.account?.loanProduct);
            setFilteredList(res.data.result.account?.schedules?.[0]?.items);
            setTransactionsList(res.data.result.account?.transactions);
          } else if (res.data.responseCode === "400") {
            setLoanAccount(null);
            setLoanProduct(null);
            setFilteredList([]);
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            setLoanAccount(null);
            setLoanProduct(null);
            setFilteredList([]);
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          setLoanAccount(null);
          setLoanProduct(null);
          setFilteredList([]);
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Internal Server Error");
      });
  };

  const handlePageClick1 = ({ selected: selectedPage }) => {
    setCurrentPage1(selectedPage);
  };
  const handlePageClick2 = ({ selected: selectedPage }) => {
    setCurrentPage2(selectedPage);
  };
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between align-items-center">
                <Form
                  className=""
                  onSubmit={(e) => {
                    e.preventDefault();
                    fetchTrans();
                  }}
                >
                  <FormGroup className="w-100">
                    <label htmlFor="status" className="form-control-label">
                      Loan Account Id
                    </label>
                    &nbsp; &nbsp;
                    <div className="d-flex w-100" style={{ gap: "12px" }}>
                      <Input
                        type="select"
                        id="status"
                        className="form-control-alternative"
                        name="statusFilter"
                        value={statusFilter}
                        onChange={(e) => changeStatus(e.target.value)}
                      >
                        <option key={""} value={""} disabled>
                          —Please select loan—
                        </option>
                        {loans.map((c) => (
                          <option key={c.key} value={c.key}>
                            {c.value}
                          </option>
                        ))}
                      </Input>
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardHeader>

              <CardBody className="mt--4">
                {loanProduct && (
                  <div className="mb-4">
                    <h4>Loan Product</h4>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">name</th>
                          <th scope="col">default Installments</th>
                          <th scope="col">min Installments</th>
                          <th scope="col">default interest Rate</th>
                          <th scope="col">interest intervalType</th>
                          <th scope="col">default Amount</th>
                          <th scope="col">allow Topup</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{loanProduct?.name}</td>
                          <td>{loanProduct?.repayment?.defaultInstallments}</td>
                          <td>{loanProduct?.repayment?.minInstallments}</td>
                          <td>{loanProduct?.interest?.defaultRate}</td>
                          <td>{loanProduct?.interest?.intervalType}</td>
                          <td>{loanProduct?.amount?.defaultAmount}</td>
                          <td>
                            {loanProduct?.topup?.allowTopup ? "Yes" : "No"}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}

                {loanAccount && (
                  <div className="mb-4">
                    <h4>Loan Account Details</h4>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">product ID</th>
                          <th scope="col">loan Amount</th>
                          <th scope="col">interest Rate</th>
                          <th scope="col">numberOf Installments</th>
                          <th scope="col">approved On</th>
                          <th scope="col">account Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{loanAccount?.productID}</td>
                          <td>{loanAccount?.loanAmount}</td>
                          <td>{loanAccount?.interestRate}</td>
                          <td>{loanAccount?.numberOfInstalments}</td>
                          <td>
                            {moment(loanAccount?.approvedOn).format("lll")}
                          </td>{" "}
                          <td>{loanAccount?.accountStatus}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}

                {loanAccount && (
                  <Nav tabs>
                    <NavItem style={navItemCls}>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          toggle("1");
                        }}
                        style={{ padding: "10px" }}
                      >
                        Repayment Schedule
                      </NavLink>
                    </NavItem>

                    <NavItem style={navItemCls}>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          toggle("2");
                        }}
                        style={{ padding: "10px" }}
                      >
                        Transactions
                      </NavLink>
                    </NavItem>
                  </Nav>
                )}
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    {filteredList.length !== 0 && (
                      <div className="mb-4">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Schedule Payment Date</th>
                              <th scope="col">Principal Amount</th>
                              <th scope="col">Principal Paid</th>
                              <th scope="col">interest Amount</th>
                              <th scope="col">interest Paid</th>
                              <th scope="col">date Repaid</th>
                              <th scope="col">balance</th>
                              <th scope="col">penalty Amount</th>
                              <th scope="col">fee Amount</th>
                              <th scope="col">Prepayment</th>
                              <th scope="col">Obliterated</th>
                              <th scope="col">payment Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredList.length > 0 &&
                              filteredList
                                .slice(
                                  currentPage1 * 10,
                                  (currentPage1 + 1) * 10
                                )
                                .map((mmo, i) => {
                                  return (
                                    <tr>
                                      <td>
                                        {moment(mmo.schedulePaymentDate).format(
                                          "lll"
                                        )}
                                      </td>
                                      <td>{mmo.principalAmount}</td>
                                      <td>{mmo.principalPaid}</td>
                                      <td>{mmo.interestAmount}</td>
                                      <td>{mmo.interestPaid}</td>
                                      <td>
                                        {mmo?.dateRepaid
                                          ? moment(mmo.dateRepaid).format("lll")
                                          : "-"}
                                      </td>
                                      <td>{mmo.balance}</td>
                                      <td>{mmo.penaltyAmount}</td>
                                      <td>{mmo.feeAmount}</td>
                                      <td>{mmo.isPrepayment ? "Yes" : "No"}</td>
                                      <td>
                                        {mmo.isObliterated ? "Yes" : "No"}
                                      </td>
                                      <td>{mmo.paymentStatus}</td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </Table>
                        {filteredList.length > 0 && (
                          <Row className="px-4 py-2">
                            <Col lg="6">
                              <div>
                                <small>
                                  <strong>
                                    Total Records : {filteredList.length}
                                  </strong>
                                </small>
                              </div>
                            </Col>

                            <Col lg="6">
                              <nav aria-label="...">
                                <div className="float-right">
                                  <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil(
                                      filteredList.length / 10
                                    )}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick1}
                                    containerClassName={"paginationV2"}
                                    subContainerClassName={"pages paginationV2"}
                                    activeClassName={"active"}
                                    forcePage={currentPage1}
                                  />
                                </div>
                              </nav>
                            </Col>
                          </Row>
                        )}
                      </div>
                    )}
                    {!loading && !filteredList.length && (
                      <div>
                        <h4>No Records Found</h4>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    {transactionsList.length !== 0 && (
                      <div className="mb-4">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">account ID</th>
                              <th scope="col">account Type</th>
                              <th scope="col">amount</th>
                              <th scope="col">balance</th>
                              <th scope="col">transaction Branch ID</th>
                              <th scope="col">transaction Origin</th>
                              <th scope="col">transaction Type</th>
                              <th scope="col">user Display Name</th>
                              <th scope="col">user ID</th>
                              <th scope="col">occurred On</th>
                              <th scope="col">payment Method Name</th>
                            </tr>
                          </thead>
                          <tbody>
                            {transactionsList.length > 0 &&
                              transactionsList
                                .slice(
                                  currentPage2 * 10,
                                  (currentPage2 + 1) * 10
                                )
                                .map((mmo, i) => {
                                  return (
                                    <tr>
                                      <td>{mmo.accountID}</td>
                                      <td>{mmo.accountType}</td>
                                      <td>{mmo.amount}</td>
                                      <td>{mmo.balance}</td>
                                      <td>{mmo.transactionBranchID}</td>
                                      <td>{mmo?.transactionOrigin}</td>
                                      <td>{mmo.transactionType}</td>
                                      <td>{mmo.userDisplayName}</td>
                                      <td>{mmo.userID}</td>
                                      <td>
                                        {mmo?.occurredOn
                                          ? moment(mmo.occurredOn).format("lll")
                                          : "-"}
                                      </td>
                                      <td>{mmo.paymentMethodName}</td>
                                    </tr>
                                  );
                                })}
                          </tbody>
                        </Table>
                        {transactionsList.length > 0 && (
                          <Row className="px-4 py-2">
                            <Col lg="6">
                              <div>
                                <small>
                                  <strong>
                                    Total Records : {transactionsList.length}
                                  </strong>
                                </small>
                              </div>
                            </Col>

                            <Col lg="6">
                              <nav aria-label="...">
                                <div className="float-right">
                                  <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={Math.ceil(
                                      filteredList.length / 10
                                    )}
                                    marginPagesDisplayed={1}
                                    pageRangeDisplayed={2}
                                    onPageChange={handlePageClick2}
                                    containerClassName={"paginationV2"}
                                    subContainerClassName={"pages paginationV2"}
                                    activeClassName={"active"}
                                    forcePage={currentPage2}
                                  />
                                </div>
                              </nav>
                            </Col>
                          </Row>
                        )}
                      </div>
                    )}
                    {transactionsList.length === 0 && (
                      <div className="text-center p-4 mt-4">
                        <h4>No Records Found</h4>
                      </div>
                    )}
                  </TabPane>
                </TabContent>

                {loading && <FullPageLoader label={"Fetching..."} />}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default LoanRepaymentSchedule;
